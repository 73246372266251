<template>
    <div>
        <section class="text-center">
            <div class="row">
                <div class="col-lg-6 col-md-8 mx-auto">
                    <h1 class="mb-3 display-1 fw-bold">
                        {{ $et("result_component", "heading") }}
                    </h1>

                    <p class="fs-4 mb-3 fw-bold" data-test="user">
                        {{
                            user.Gender === "other"
                                ? ""
                                : $et("salutation", $h(user.Gender))
                        }}

                        {{ user.Name }}
                    </p>

                    <p class="fs-4 mb-3" data-test="time-taken">
                        {{ $et("result_component", "time_taken") }}
                        <span class="fw-bold">{{ timeTaken }} </span>
                    </p>

                    <h2 data-test="result">{{ result }}</h2>
                    <h3 v-if="user.Score === templates.length">
                        {{ this.$et("result_component", "congratulations") }}
                    </h3>

                    <div class="d-lg-flex justify-content-center d-block mx-2">
                        <base-button
                            data-test="restart"
                            type="button"
                            size="lg"
                            class="my-2 my-lg-0 w-100 w-md-50"
                            @clicked="retakeTest"
                            id="restart"
                        >
                            {{ $et("result_component", "restart") }}
                        </base-button>
                    </div>
                </div>
            </div>
        </section>

        <section class="bg-light">
            <div class="row mx-2 no-gutters flex-wrap-wrap">
                <div
                    class="col-12 col-md-3 my-3"
                    :key="template.id"
                    v-for="template in templates"
                >
                    <phishing-card
                        class="h-100"
                        :template="template"
                    ></phishing-card>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import PhishingCard from "@/components/PhishingCard";
import $DateTimeHelper from "@/helpers/DateTimeHelper";
import { mapGetters, mapActions } from "vuex";

export default {
    name: "ResultPage",
    components: { PhishingCard },
    computed: {
        ...mapGetters("session", ["user", "startTime", "templates"]),
        ...mapGetters("settings", ["FinishHook"]),

        timeTaken() {
            let duration = $DateTimeHelper.timeDifference(
                this.$moment(new Date()),
                this.startTime
            );
            return duration;
        },

        result() {
            return this.$I18nFormatter.replacePlaceholders(
                this.$et("result_component", "result_msg"),
                {
                    score: this.user.Score,
                    length: this.templates?.length,
                }
            );
        },
    },

    beforeMount() {
        if (!this.templates) {
            return this.$router.replace("/");
        }
    },

    created() {
        if (this.templates) {
            this.calulateScore();
        }
    },

    mounted() {
        if (this.FinishHook) {
            try {
                this.report();
            } catch (e) {
                console.error(e);
            }
        }
    },

    methods: {
        ...mapActions("quiz", ["publishResult"]),

        retakeTest() {
            this.$router.push({ path: "/" });
        },

        calulateScore() {
            let score = 0;

            this.templates.forEach((el) => {
                if (el.isPhishing === el.answer) {
                    score++;
                }
            });

            this.user.Score = score;
        },

        async report() {
            await this.publishResult(this.calculatePercentage());
        },

        calculatePercentage() {
            return (this.user.Score / this.templates?.length || 1) * 100;
        },
    },
};
</script>