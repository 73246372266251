<template>
  <div class="card">
    <div class="card-body d-flex flex-column h-100 justify-content-between">
      <div
        class="
          card-text
          white-space-nowrap
          text-overflow-ellipsis
          flex-grow
          mb-3
        "
        :data-test="`subject-${template.id}`"
      >
        {{ template.subject }}
      </div>
      <img
        :src="template.thumbnail"
        class="card-img-top"
        :alt="template.thumbnail"
        :data-test="`thumbnail-${template.id}`"
      />
      <div class="text-center">
        <hr />
        <base-icon
          :icon="icon"
          :color="color"
          size="1.5rem"
          :data-test="`icon-${template.id}`"
        ></base-icon>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PhishingCard",
  props: {
    template: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    isCorrect() {
      return this.template.isPhishing == this.template.answer;
    },
    icon() {
      return this.isCorrect ? "bi-check" : "bi-x";
    },
    color() {
      return this.isCorrect ? "#146c43" : "#a52834";
    },
  },
};
</script>